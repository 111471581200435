.footer {
  width: 960px;
  height: 90px;
  margin: 0 auto;
  background-image: url(../img/footerbg.png);
  .text {
    padding-top: 20px;
    p {
      text-align: center;
      font-size: 13px;
      line-height: 30px;
    }
  }
}
