.name {
  width: 960px;
  height: 30px;
  margin: 0 auto;
  background-color: @nameColor;
  margin-top: 10px;
  p {
    color: #fff;
    text-align: right;
    padding-right: 10px;
    font-size: 13px;
    line-height: 30px;
  }
}
