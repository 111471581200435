.main {
  width: 960px;
  margin: 0 auto;
  padding-top: 10px;
  .left {
    width: 430px;
    display: inline-block;
  }
  .right {
    width: 515px;
    display: inline-block;
    background-image: url(../img/newsbg.gif);
    margin-left: 10px;
  }
}
