.nav {
  width: 960px;
  height: 40px;
  margin: 0 auto;
  border-radius: 5px;
  .navMenu {
    letter-spacing:  -5px;
    border-radius: 5px;
    overflow: hidden;
    li {
      letter-spacing: normal;
      display: inline-block;
      line-height: 40px;
      font-size: 16px;
      width: 106.6px;
      text-align: center;
      // background-color: @navColor;
      background-image: url(../img/nav1.png);
      background-size: 100%;
      a {
        text-decoration: none;
        color: #fff;
      }
      ul {
        position: absolute;
        top: 240px;
        display: none;
        li {
          background-image: none;
          background-color: @subNavColor;
          z-index: 200;
        }
      }
    }
    li:hover {
      a {
        font-size: 17px;
      }
      ul {
        display: block;
        li {
          position: relative;
          display: block;
          z-index: 200;
          a {
            font-size: 14px;
          }
        }
      }
    }
  }
}
