@import "var.less";
@import "top.less";
@import "nav.less";
@import "main.less";
@import "res.less";
@import "intro.less";
@import "news.less";
@import "info.less";
@import "name.less";
@import "link.less";
@import "footer.less";
@import "listContent.less";
@import "pageContent.less";
* {
  padding: 0;
  margin: 0;
}
body {
  font-family: 'Microsoft Yahei';
  width: 100%;
  // background-color: #f1f1f1;
}
li {
  list-style: none;
}
.cleanfix {
  clear: both;
}
#warpper {
  width: 100%;
  margin: 0;
  padding: 0;
}
#small {
  font-size: 14px;
}

.title {
  padding: 10px;
  border-bottom: 2px solid red;
  h4 {
    font-size: 18px;
    color: @introWord;
    float: left;
  }
  img {
    float: right;
  }
}
.list {
  font-size: 14px;
  span {
   float: right;
  }
  a {
    vertical-align: middle;
    width: 375px;
    display: inline-block;
    text-decoration: none;
    color: #000;
    white-space: nowrap;
    text-overflow: ellipsis;
    word-wrap: break-word;
    word-break: break-all;
    overflow: hidden;
  }
  ul {
    li {
      line-height: 35px;
      padding-left: 10px;
      img {
        margin-right: 5px;
      }
    }
  }
}
