.intro {
  background-color: @introColor;
  padding: 20px;
  border-radius: 10px;
  margin-top: 20px;
  border: 1px solid #f1f1f1;
  h4 {
    text-align: left;
    padding-left: 10px;
    padding-bottom: 10px;
    color: @introWord;
    font-size: 18px;
    border-bottom: 2px solid red;
  }
  .intro-content {
    line-height: 27px;
    padding: 0 20px;
    font-size: 16px;
    height: 191.2px;
    overflow: hidden;
  }
  a {
    display: block;
    padding: 0 20px;
    margin-top: 20px;
    text-decoration: none;
    color: #000;
    text-align: right;
  }
}
