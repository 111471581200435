.link {
  width: 960px;
  margin: 0 auto;
  ul {
    li {
      display: inline-block;
      width: 155px;
      text-align: right;
    }
  }
}
