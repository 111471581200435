.list-content {
  width: 960px;
  margin: 0 auto;
  margin-top: 10px;
  border-radius: 10px;
  border: 1px solid #f1f1f1;
  .list {
    padding:0 10px;
    a {
      width: 800px;
    }
  }
  .title {
    p {
      padding-left: 10px;
      font-size: 14px;
    }
    border-bottom: 1px solid #f1f1f1;
  }
  .page {
    text-align: center;
    height: 30px;
    line-height: 30px;
  }
}
