.page-content {
  width: 960px;
  margin: 0 auto;
  margin-top: 10px;
  border-radius: 10px;
  border: 1px solid #f1f1f1;
  .content-position {
    padding: 10px;
    border-bottom: 1px solid #f1f1f1;
    h3 {
      font-size: 15px;
      font-weight: normal;
    }
  }
  .text-content {
    .content-title {
      padding: 20px;
      text-align: center;
      p {
        margin-top: 10px;
      }
    }
    .content-text {
      padding: 20px;
    }
  }
}
