.resSlide {
  margin-top: 5px;
  .rslides {
    li {
      img {
        width: 430px;
        height: 300px;
      }
    }
  }
}
